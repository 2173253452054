
import MainView from './containers/MainView'
import "./App.css"
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="appView">
      <MainView>
        
      </MainView>
    </div>
    
  );
}

export default App;
